// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@import '../../node_modules/@mdi/font/css/materialdesignicons.min.css';
@import '../../node_modules/material-design-icons-iconfont/dist/material-design-icons.css';

@font-face {
    font-family: 'GT America';
    font-style: normal;
    font-weight: 400;
    src: url(../aarp-uikit/fnt/GT-America-Regular.otf);
}
@font-face {
    font-family: 'GT America';
    font-style: normal;
    font-weight: 500;
    src: url(../aarp-uikit/fnt/GT-America-Standard-Medium.ttf);
}
@font-face {
    font-family: 'GT America';
    font-style: normal;
    font-weight: 700;
    src: url(../aarp-uikit/fnt/GT-America-Standard-Bold.otf);
}

#app {
    height: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    height: 100%;
}

a {
    text-decoration: none;
    outline: none;
}

#content-holder {
    width: 70%;
    height: 100%;
    border: 1px solid #ccc;
    margin-right: 10px;
    padding: 10px;
}

#sidebar {
    border: 1px solid #ccc;
    width: 30%;
    height: 100%;
    padding: 10px;
    display: flex;

    .sidebar-content {
        width: 100%;
        height: 100%;
        display: block;

        #faq {
            width: 100%;
            height: 80%;
            display: block;
        }
    }
}

.text-center {
    text-align: center;
}


